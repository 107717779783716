/* eslint-disable no-underscore-dangle */
// import React from 'react';
import * as Yup from 'yup';
// import { objectAsArray } from 'lib/utils';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import {
  ColumnSet, MutationAction, QueryAction,
  Resource,
} from 'ants/resource';
import { isDeletedValue } from 'core/Form';
import {
  MatchScorecardReadQuery,
  MatchScorecardUpdateMutation,
} from './inningsQuery';

const BowlersValidation = () => {
  const validateBowler = (value, context) => {
    let bowlers = objectAsArray(
      context?.options?.context?.initialValues?.scorecard?.bowlers,
    ).filter(
      (bowler) => !isDeletedValue(bowler) && bowler?.player !== '',
    ).map((bowler) => bowler?.player);
    bowlers = new Set(bowlers);

    const batters = objectAsArray(value)?.filter((batter) => !isDeletedValue(batter));
    for (let i = 0; i < batters.length; i += 1) {
      if (batters[i]?.bowler && !bowlers.has(batters[i]?.bowler)) {
        return context.createError({
          message: `Bowler assigned to the batsman at order ${batters[i]?.order} is not valid.`,
        });
      }
    }
    return true;
  };

  return Yup.mixed().test('validateBowler', 'Bowler is required', validateBowler);
};

export const InningsResource = Resource({
  resourceId: 'matchInnings',
  name: 'MatchInnings',
  storeId: 'MatchInnings',
  pageKey: 'sports.cricket.match.innings',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Scorecard',
      shape: Yup.object().shape({
        overs: Yup.number().nullable().default(0.0),
        batters: BowlersValidation(),
        bowlers: Yup.mixed(),
        wicket_order: Yup.mixed(),
        team_wide: Yup.number().nullable().default(0),
        team_extra_runs: Yup.number().nullable().default(0),
        team_penality: Yup.number().nullable().default(0),
        team_bye: Yup.number().nullable().default(0),
        team_legbye: Yup.number().nullable().default(0),
        team_noball: Yup.number().nullable().default(0),
        team_fours: Yup.number().nullable().default(0),
        team_sixes: Yup.number().nullable().default(0),
      }),
      referenceFields: ['batters.fielders.fielder'],
      viewKey: 'scorecard',
      historyKey: 'ScorecardInput',
    }),
  ],

  listPrimaryActions: [],
  queries: {
    read: QueryAction({
      query: MatchScorecardReadQuery,
      responsePath: 'sports_cricket_scorecard_read',
      resourceNamePath: 'item.match.name',
    }),
  },
  mutations: {
    updateScorecard: MutationAction({
      mutation: MatchScorecardUpdateMutation,
      cs: ['scorecard'],
      responsePath: 'sports_cricket_scorecard_update',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.scorecard.batters = arrayAsObject(resp.scorecard.batters) ?? {};
        Object.keys(resp.scorecard.batters).forEach((batterKey) => {
          resp.scorecard.batters[batterKey].fielders = arrayAsObject(
            resp.scorecard.batters[batterKey].fielders,
          ) ?? {};

          Object.keys(resp.scorecard.batters[batterKey].fielders).forEach((fielderKey) => {
            const fvalue = resp.scorecard.batters[batterKey].fielders[fielderKey];
            if (fvalue && fvalue._hashkey) {
              resp.scorecard.batters[batterKey].fielders[fielderKey] = fvalue._hashkey;
            } else if (fvalue && fvalue.hashkey) {
              resp.scorecard.batters[batterKey].fielders[fielderKey] = fvalue.hashkey;
            } else if (fvalue) {
              resp.scorecard.batters[batterKey].fielders[fielderKey] = fvalue;
            } else {
              resp.scorecard.batters[batterKey].fielders[fielderKey] = undefined;
            }
          });
        });
        // resp.scorecard.batters.fielders = arrayAsObject(resp.scorecard.batters.fielders) ?? {};
        resp.scorecard.bowlers = arrayAsObject(resp.scorecard.bowlers) ?? {};
        resp.scorecard.wicket_order = arrayAsObject(resp.scorecard.wicket_order) ?? {};
        return {
          ...resp,
          scorecard: {
            batters: arrayAsObject(resp.scorecard.batters),
            bowlers: arrayAsObject(resp.scorecard.bowlers),
            wicket_order: arrayAsObject(resp.scorecard.wicket_order),
            overs: resp.scorecard.overs,
            team_wide: resp.scorecard.team_wide,
            team_extra_runs: resp.scorecard.team_extra_runs,
            team_penality: resp.scorecard.team_penality,
            team_bye: resp.scorecard.team_bye,
            team_legbye: resp.scorecard.team_legbye,
            team_noball: resp.scorecard.team_noball,
            team_fours: resp.scorecard.team_fours,
            team_sixes: resp.scorecard.team_sixes,
          },
        };
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.scorecard.batters = objectAsArray(resp.scorecard.batters);
        resp.scorecard.batters = resp.scorecard.batters.map((batter, index) => {
          const fielders = objectAsArray(batter.fielders).map((fielder, i) => {
            if (fielder?.fielder?.hashkey) {
              return {
                ...fielder,
                assist_index: i,
                role: i === 0 ? 'Primary' : 'Assist',
                fielder: { _hashkey: fielder?.fielder?.hashkey },
              };
            }
            if (fielder?.fielder) {
              return {
                ...fielder, assist_index: i, role: i === 0 ? 'Primary' : 'Assist', fielder: { _hashkey: fielder?.fielder },
              };
            }
            return undefined;
          }).filter((x) => x);
          const fallOfWicket = {
            score: parseInt(batter?.fall_of_wicket?.score, 10) || 0,
            overs: parseFloat(batter?.fall_of_wicket?.overs, 2) || 0.0,
            ball: parseInt(batter?.fall_of_wicket?.ball, 10) || 0,
          };
          return {
            ...batter,
            is_dismissed: (batter?.wicket_type && batter?.wicket_type === 'Retired') ? false : batter?.is_dismissed,
            runs: batter.runs || 0,
            fours: batter.fours || 0,
            sixes: batter.sixes || 0,
            strike_rate: batter.strike_rate || 0,
            balls: batter.balls || 0,
            order: batter?.order || index + 1,
            fall_of_wicket: fallOfWicket,
            fielders,
          };
        });
        resp.scorecard.bowlers = objectAsArray(resp.scorecard.bowlers) ?? [];
        resp.scorecard.wicket_order = objectAsArray(resp.scorecard.wicket_order) ?? [];
        return {
          ...resp,
          scorecard: {
            batters: objectAsArray(resp.scorecard.batters),
            bowlers: objectAsArray(resp.scorecard.bowlers).map((bowler, index) => (
              {
                ...bowler,
                order: bowler?.order || index + 1,
                wickets: bowler.wickets || 0,
                maidens: bowler.maidens || 0,
                runs: bowler.runs || 0,
                wides: bowler.wides || 0,
                no_balls: bowler.no_balls || 0,
                overs: parseFloat(bowler.overs || 0),
              }
            )),
            wicket_order: objectAsArray(resp.scorecard.wicket_order),
            overs: resp.scorecard.overs,
            team_wide: resp.scorecard.team_wide || 0,
            team_extra_runs: resp.scorecard.team_extra_runs || 0,
            team_penality: resp.scorecard.team_penality || 0,
            team_bye: resp.scorecard.team_bye || 0,
            team_legbye: resp.scorecard.team_legbye || 0,
            team_noball: resp.scorecard.team_noball || 0,
            team_fours: resp.scorecard.team_fours || 0,
            team_sixes: resp.scorecard.team_sixes || 0,
          },
        };
      },
    }),
  },
});
