import { gql } from '@apollo/client';

export const ImportCreateQuery = gql`
  mutation ImportCreateQuery($tournament: String!, $getFresh: Boolean) {
    imports_source_import_create(tournament: $tournament, get_fresh: $getFresh) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
      importable {
        resource {
          account
          sport
          key
          _hashkey
        }
        import_meta {
          created_at
          updated_at
          created_by {
            key
            _hashkey
          }
          updated_by {
            key
            _hashkey
          }
          started_at
          ended_at
        }
        imported_resources {
          error_msg
          regions {
            key {
              account
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              _hashkey
            }
          }
          associations {
            key {
              account
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              _hashkey
            }
          }
          competitions {
            key {
              account
              sport
              association
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              key
              _hashkey
            }
          }
          tournaments {
            key {
              account
              sport
              association
              competition
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              competition
              key
              _hashkey
            }
          }
          players {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
          }
          teams {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
          }
          people {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
          }
          stadiums {
            key {
              account
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              _hashkey
            }
          }
          tours {
            key {
              account
              sport
              association
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              key
              _hashkey
            }
          }
          matches {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            existing
            completed
            import_ball
            is_mg101
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
            subtitle
            import_ball
            is_mg101
            error_msg
          }
        }
      }
    }
  }
`;

export const ImportListQuery = gql`
  query ImportListQuery($page: PagedRequestInput) {
    imports_source_import_list(page: $page) {
      items {
        resource {
          account
          sport
          key
          hashkey: _hashkey
        }
        import_meta {
          created_at
          updated_at
          created_by {
            key
            _hashkey
          }
          updated_by {
            key
            _hashkey
          }
          started_at
          ended_at
        }
        imported_resources {
          error_msg
          regions {
            key {
              account
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              hashkey: _hashkey
            }
          }
          associations {
            key {
              account
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              hashkey: _hashkey
            }
          }
          competitions {
            key {
              account
              sport
              association
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              key
              hashkey: _hashkey
            }
          }
          tournaments {
            key {
              account
              sport
              association
              competition
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              competition
              key
              hashkey: _hashkey
            }
          }
          players {
            key {
              account
              sport
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              hashkey: _hashkey
            }
          }
          teams {
            key {
              account
              sport
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              hashkey: _hashkey
            }
          }
          people {
            key {
              account
              sport
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              hashkey: _hashkey
            }
          }
          stadiums {
            key {
              account
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              hashkey: _hashkey
            }
          }
          tours {
            key {
              account
              sport
              association
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              key
              hashkey: _hashkey
            }
          }
          matches {
            key {
              account
              sport
              key
              hashkey: _hashkey
            }
            name
            status
            existing
            completed
            import_ball
            is_mg101
            overwrite_with {
              account
              sport
              key
              hashkey: _hashkey
            }
            subtitle
            import_ball
            is_mg101
            error_msg
          }
        }
      }
      page {
        next_page_key
        count
        prev_page_key
      }
    }
  }
`;

export const ImportReadQuery = gql`
  query ImportReadQuery($resource: SourceImportsInput!) {
    imports_source_import_read(resource: $resource) {
      item {
        resource {
          account
          sport
          key
          _hashkey
        }
        import_meta {
          created_at
          updated_at
          created_by {
            key
            _hashkey
          }
          updated_by {
            key
            _hashkey
          }
          started_at
          ended_at
        }
        imported_resources {
          error_msg
          regions {
            key {
              account
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              _hashkey
            }
          }
          associations {
            key {
              account
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              _hashkey
            }
          }
          competitions {
            key {
              account
              sport
              association
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              key
              _hashkey
            }
          }
          tournaments {
            key {
              account
              sport
              association
              competition
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              competition
              key
              _hashkey
            }
          }
          players {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
          }
          teams {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
          }
          people {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
          }
          stadiums {
            key {
              account
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              key
              _hashkey
            }
          }
          tours {
            key {
              account
              sport
              association
              key
              _hashkey
            }
            name
            status
            existing
            completed
            overwrite_with {
              account
              sport
              association
              key
              _hashkey
            }
          }
          matches {
            key {
              account
              sport
              key
              _hashkey
            }
            name
            status
            import_ball
            is_mg101
            existing
            completed
            overwrite_with {
              account
              sport
              key
              _hashkey
            }
            subtitle
            import_ball
            is_mg101
            error_msg
          }
        }
      }
    }
  }
`;

export const ImportStartMutation = gql`
mutation ImportStartMutation(
  $resource: SourceImportsInput!, $restart: Boolean) {
  imports_source_import_start(resource: $resource, restart: $restart) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ImportUpdateMutation = gql`
mutation ImportUpdateMutation(
  $resource: SourceImportsInput!, $importable: SourceImportsImportedInput!) {
  imports_source_import_update(resource: $resource, importable: $importable) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const ImportStatusQuery = gql`
  query ImportStatusQuery($resource: SourceImportsInput!) {
    imports_source_import_status(resource: $resource) {
      status {
        error_msg
        regions {
          total_resources
          completed_resources
          is_completed
        }
        associations {
          total_resources
          completed_resources
          is_completed
        }
        competitions {
          total_resources
          completed_resources
          is_completed
        }
        tournaments {
          total_resources
          completed_resources
          is_completed
        }
        players {
          total_resources
          completed_resources
          is_completed
        }
        teams {
          total_resources
          completed_resources
          is_completed
        }
        people {
          total_resources
          completed_resources
          is_completed
        }
        stadiums {
          total_resources
          completed_resources
          is_completed
        }
        tours {
          total_resources
          completed_resources
          is_completed
        }
        matches {
          total_resources
          completed_resources
          is_completed
        }
      }
    }
  }
`;
