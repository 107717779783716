import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const MatchCreateMutation = gql`
mutation MatchCreateMutation(
  $start_at: RzDateTime!,
  $format: CricketMatchFormat!,
  $sub_title: String!,
  $name: String!,
  $short_name: String!,
  $stadium: StadiumInput,
  $tournament: TournamentInput!,
  $tournamentRound: String!,
  $tournamentGroup: String!,
  $status: CricketMatchStatusInput!,
  $teams: MatchTeamsInput!,
  $mg: CricketMetricGroup!
) {
  sports_cricket_match_create(
    start_at: $start_at, format: $format,
    sub_title: $sub_title, name: $name, short_name: $short_name,
    stadium: $stadium, tournament: $tournament, teams: $teams, mg: $mg,
    tournament_round: $tournamentRound, tournament_group: $tournamentGroup,
    status: $status
  ) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
    name
  }
}
`;

export const MatchSearchQuery = gql`
query MatchSearchQuery($search: String!,
  $page: PagedRequestInput,
  $filters: MatchFiltersInput,
  $tags: [String!],
  $properties: RzRaw,
  $matchDay: DateFilterInput,
  $liveMatches: Boolean,
  $recentMatches: String,
) {
  sports_cricket_match_search(
    search: $search,
    page: $page,
    filters: $filters,
    tags: $tags,
    properties: $properties
    date_filter: $matchDay,
    show_live_matches_only: $liveMatches
    recent_matches_tz: $recentMatches
    ) {
    items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      match {
        name
        internal_name
        start_at
        format
        status
        short_name
        sub_title
        stadium {
          account
          key
          hashkey: _hashkey
        }
        tournament {
          account
          sport
          association
          competition
          key
          hashkey: _hashkey
        }
        headless
      }
      teams {
        team_a {
          account
          sport
          key
          hashkey: _hashkey
        }
        team_b {
          account
          sport
          key
          hashkey: _hashkey
        }
      }
      merge_with {
        tournament_key
        tournament_round_key
        tournament_group_key
        review_status
      }
      rules {
        format
        mg
        overs_per_innings
        drs_per_innings
        balls_per_over
        player_per_team
        bench_per_team
        bowler_per_innings
        batsman_per_innings
        validate_players_count_per_team
        allow_induvidual_team_squad_update
        enable_ball_of_dismissed_stats
      }
      tournament_details {
        round_key
        group_key
      }
      status {
        published
        notes
      }
      deleted_status {
        mark_as_deleted
        delete_after
      }
      data {
        tags
        properties
      }
      computed_searchables {
        gender
        year_month
        association {
          account
          key
          hashkey: _hashkey
        }
        competition {
          account
          sport
          association
          key
          hashkey: _hashkey
        }
      }
      rules {
        mg
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const MatchTopCountsQuery = gql`
query MatchTopCountsQuery($search: String!,
  $page: PagedRequestInput,
  $filters: MatchFiltersInput,
  $tags: [String!],
  $properties: RzRaw,
  $matchDay: DateFilterInput,
  $liveMatches: Boolean,
  $recentMatches: String
) {
  sports_cricket_match_search(
    search: $search,
    page: $page,
    filters: $filters,
    tags: $tags,
    properties: $properties
    date_filter: $matchDay,
    show_live_matches_only: $liveMatches
    recent_matches_tz: $recentMatches) {
    top_counts {
      genders {
        key
        name
        count
      }
      tournaments {
        key
        name
        count
      }
      associations {
        key
        name
        count
      }
      competitions {
        key
        name
        count
      }
      month {
        key
        name
        count
      }
      stadiums {
        key
        name
        count
      }
      teams {
        key
        name
        count
      }
    }
  }
}
`;

export const MatchHeadlessSearchQuery = gql`
  query MatchHeadlessSearchQuery($search: String!,
    $page: PagedRequestInput,
    $filters: MatchFiltersInput,
    $tags: [String!],
    $properties: RzRaw,
  ) {
    sports_cricket_match_review_search(search: $search, page: $page, tags: $tags, properties: $properties, filters: $filters) {
      items {
        resource {
          account
          sport
          key
          hashkey: _hashkey
        }
        match {
          name
          internal_name
          start_at
          format
          status
          short_name
          sub_title
          stadium {
            account
            key
            hashkey: _hashkey
          }
          tournament {
            account
            sport
            association
            competition
            key
            hashkey: _hashkey
          }
          headless
        }
        teams {
          team_a {
            account
            sport
            key
            hashkey: _hashkey
          }
          team_b {
            account
            sport
            key
            hashkey: _hashkey
          }
        }
        merge_with {
          tournament_key
          tournament_round_key
          tournament_group_key
          review_status
        }
        rules {
          format
          mg
          overs_per_innings
          drs_per_innings
          balls_per_over
          player_per_team
          bench_per_team
          bowler_per_innings
          batsman_per_innings
          validate_players_count_per_team
          allow_induvidual_team_squad_update
          enable_ball_of_dismissed_stats
          allow_same_player_in_both_teams
          allow_squad_update_without_toss
        }
        tournament_details {
          round_key
          group_key
        }
        status {
          published
          notes
        }
        deleted_status {
          mark_as_deleted
          delete_after
        }
        create_computed {
          incremental_id
        }
        data {
          tags
          properties
        }
        computed_searchables {
          gender
          year_month
          association {
            account
            key
            hashkey: _hashkey
          }
          competition {
            account
            sport
            association
            key
            hashkey: _hashkey
          }
        }
      }
      page {
        next_page_key
        count
        prev_page_key
      }
      top_counts {
        genders {
          key
          name
          count
        }
        tournaments {
          key
          name
          count
        }
        associations {
          key
          name
          count
        }
        competitions {
          key
          name
          count
        }
        month {
          key
          name
          count
        }
        stadiums {
          key
          name
          count
        }
        teams {
          key
          name
          count
        }
      }
    }
  }
`;

export const TournamentTeam = `
  resource {
    hashkey: _hashkey
  }
  team { 
    name,
    short_name,
    region {
      account
      key
      hashkey: _hashkey
    }
    gender
  }
  image {
    image_url
  }
`;

export const TournamentSquadPlayers = `
  resource {
    hashkey: _hashkey
  }
  team {
    captain {
      key
      hashkey: _hashkey
    } 
  }
  legacy_data {
    key
  }
  squad {
    squad {
      player {
        account
        sport
        key
        hashkey: _hashkey
      }
      batting_style
      bowling_style {
        arm
        pace
        type
      }
      frequent_bowling_deliveries
      playing_role
      name
      jersey_name
      state
      image_url
      notes
      reason
      replaced_by {
        account
        sport
        key
        hashkey: _hashkey
      }
    }
  }
`;

export const MatchReadQuery = gql`
query MatchReadQuery($resource: MatchInput!,
  $withHistory: Boolean,
) {
  sports_cricket_match_read(resource: $resource, with_history: $withHistory) {
    related {
      team_a {
        ${TournamentTeam}
      }
      team_b {
        ${TournamentTeam}
       }
      team_a_squad {
        ${TournamentSquadPlayers}
      }
      team_b_squad {
        ${TournamentSquadPlayers}
      }
    }
    item {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      innings_metadata {
        innings_metadata {
          index
          revised_overs
          revised_runs
          is_completed
          is_match_end
        }
      }
      match {
        name
        internal_name
        start_at
        format
        status
        short_name
        sub_title
        stadium {
          account
          key
          hashkey: _hashkey
        }
        tournament {
          account
          sport
          association
          competition
          key
          hashkey: _hashkey
        }
        headless
      }
      rules {
        format
        mg
        overs_per_innings
        drs_per_innings
        balls_per_over
        player_per_team
        bench_per_team
        bowler_per_innings
        batsman_per_innings
        validate_players_count_per_team
        allow_induvidual_team_squad_update
        enable_ball_of_dismissed_stats
        allow_same_player_in_both_teams
        allow_squad_update_without_toss
        is_captain_wicketkeeper_required
        runs_per_wide
        runs_per_no_ball
        tiebreaker
        tiebreaker_type
        max_innings_per_team
        tiebreaker_overs
      }
      play {
        play_status
        break_type
        break_seconds
        break_note
        internal_notes
        public_msg
        day
        weather {
          temperature
          condition
          icon
        }
        expected_start_time
      }
      teams {
        team_a {
          account
          sport
          key
          hashkey: _hashkey
        }
        team_b {
          account
          sport
          key
          hashkey: _hashkey
        }
      }
      tournament_details {
        round_key
        group_key
      }
      toss {
        winner
        elected
        squad_confirmed
        called
      }
      team_a {
        team_key
        tournament_team_key
        team_code
        team_name
      }
      team_b {
        team_key
        tournament_team_key
        team_code
        team_name
      }
      team_a_squad {
        players
        bench
        supersub
        captain
        keeper
      }
      team_b_squad {
        players
        bench
        supersub
        captain
        keeper
      }
      innings {
        innings {
          index
          batting
        }
      }
      computed {
        dl_applied_src
        end_at
      }
      review_checklist {
        pre_match_review
        pre_match_validated
        post_match_review
        post_match_validated
      }
      result {
        winner
        typ
        dl_applied
        win_by
      }
      reward {
        pom
        team_a_points
        team_b_points
        team_a_nrr
        team_b_nrr
      }
      status {
        published
        notes
      }
      deleted_status {
        mark_as_deleted
        delete_after
      }
      legacy_data {
        key
      }
      data {
        tags
        properties
      }
      umpires {
        tv_umpires
        match_umpires
        reserve_umpires
        match_referee
      }
      sessions {
        sessions {
          day
          session
          innings
          first_over_str
          last_over_str
          session_start_day_remaining_overs_est
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const MatchUpdateMutation = gql`
mutation MatchUpdateMutation(
  $resource: MatchInput!,
  $match: MatchMatchInput!,
  $teams: MatchTeamsInput!,
  $status: CricketMatchStatusInput!,
  $tournamentDetails: MatchTournamentInput!,
  $version: RecordStage,
) {
  sports_cricket_match_update(resource: $resource, tournament_details: $tournamentDetails,
    status: $status, match: $match, teams: $teams, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateRulesMutation = gql`
mutation MatchUpdateRulesMutation(
  $resource: MatchInput!,
  $rules: MatchRulesInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_rules(resource: $resource, rules: $rules, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateTossMutation = gql`
mutation MatchUpdateTossMutation(
  $resource: MatchInput!,
  $toss: MatchTossInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_toss(resource: $resource, toss: $toss, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateUmpireMutation = gql`
mutation MatchUpdateUmpireMutation(
  $resource: MatchInput!,
  $umpires: MatchUmpiresInput!,
  $version: RecordStage,
){
  sports_cricket_match_update_umpires(resource: $resource, umpires: $umpires, version: $version){
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdatePlayMutation = gql`
mutation MatchUpdatePlayMutation(
  $resource: MatchInput!,
  $play: MatchPlayInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_play(resource: $resource, play: $play, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateInningsAddMutation = gql`
mutation MatchUpdateInningsAddMutation(
  $resource: MatchInput!,
  $new_innings: InningsDetailsInput!,
  $session: MatchSessionItemInput,
  $version: RecordStage,
  $opening_players: OpeningPlayersInput
) {
  sports_cricket_match_update_innings_add(
    resource: $resource,
    new_innings: $new_innings,
    session: $session,
    opening_players: $opening_players,
    version: $version,
  ) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateInningsMutation = gql`
mutation MatchUpdateInningsMutation(
  $resource: MatchInput!,
  $innings: InningsDetailsInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_innings_update(
    resource: $resource,
    innings: $innings,
    version: $version,
  ) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateSquadMutation = gql`
mutation MatchUpdateSquadMutation(
  $resource: MatchInput!,
  $team_a_squad: MatchTeamASquadInput!,
  $team_b_squad: MatchTeamBSquadInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_squad(resource: $resource, team_a_squad: $team_a_squad, team_b_squad: $team_b_squad, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchDeleteMutation = gql`
mutation MatchDeleteMutation(
  $resource: MatchInput!,
  $name: String!) {
  sports_cricket_match_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

// TODO: should be moved to tournament
export const MatchUpdateTeamMutation = gql`
mutation MatchUpdateTeamMutation(
  $resource: TournamentTeamInput!,
  $newPlayers: [CricketTournamentTeamPlayerInput!]!) {
  sports_cricket_tournament_team_add_players(resource: $resource, new_players: $newPlayers) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const PlayerSearchQuery = gql`
query PlayerSearchQuery(
  $search: String!,
  $page: PagedRequestInput,
) {
  sports_player_search(search: $search, page: $page) {
    items {
      resource {
        account
        sport
        key
        hashkey: _hashkey
      }
      player {
        name
        jersey_name
      }
      cricket {
        playing_role
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const TournamentGroupTeamsQuery = gql`
query TournamentGroupTeamsQuery(
  $resource: TournamentRoundInput!,
  $groupIndex: Int!,
) {
  sports_cricket_tournament_round_group_teams(resource: $resource, group_index: $groupIndex) {
    items {
      resource {
        key
        hashkey: _hashkey
      }
      name
      short_name
      image_url
    }
  }
}
`;

export const TournamentRoundList = gql`
query TournamentRoundList(
  $tournament: TournamentInput!,
  $page: PagedRequestInput,
) {
  sports_cricket_tournament_round_list(tournament: $tournament, page: $page) {
    items {
      resource {
        tournament
        key
        hashkey: _hashkey
      }
      tournament_round {
        name
        index
        groups {
          key
          name
          index
          teams
          matches {
            resource {
              account
              sport
              key
              hashkey: _hashkey
            }
            start_at
            teams {
              team_a {
                tournament
                team
                hashkey: _hashkey
              }
              team_b {
                tournament
                team
                hashkey: _hashkey
              }
            }
            sub_title
            internal_name
            status
            stadium {
              account
              key
              hashkey: _hashkey
            }
            published
            notes
          }
          legacy_data {
            key
            synced
          }
        }
        type
        enable_points
        group_based_points
      }
      legacy_data {
        key
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const MatchUpdateRewardMutation = gql`
mutation MatchUpdateRewardMutatio(
  $resource: MatchInput!,
  $reward: MatchRewardInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_reward(resource: $resource, reward: $reward, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateResultMutation = gql`
mutation MatchUpdateResultMutation(
  $resource: MatchInput!,
  $result: MatchResultInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_result(resource: $resource, result: $result, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;
export const MatchUpdateReviewMutation = gql`
mutation MatchUpdateReviewMutation(
  $resource: MatchInput!,
  $review: MatchReviewCheckListInput!,
  $version: RecordStage
) {
  sports_cricket_match_update_review_checklist(resource: $resource, review: $review, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchDataUpdateMutation = gql`
mutation MatchDataUpdateMutation(
  $resource: MatchInput!,
  $tags: [String!],
  $properties: RzRaw,
  $version: RecordStage) {
    sports_cricket_data_update_match(
      resource: $resource, tags: $tags, properties: $properties, version: $version) {
        status {
          okay
          errors {
            msg
            field_path
            code
          }
          key
        }
      }
    }
`;

export const SquadMatchAutofillQuery = gql`
query SquadMatchAutofillQuery(
  $resource: MatchInput!,
  $team: TeamInput!,
) {
  sports_cricket_match_autofill_squad(resource: $resource, team: $team) {
    team_squad {
      players
      bench
      captain
      keeper
    }
  }
}
`;

export const UmpireSearchQuery = gql`
query UmpireSearchQuery(
  $search: String!,
  $page: PagedRequestInput,
){
  sports_person_umpire_search(search: $search, page: $page) {
    items {
      resource {
        account
        sport
        key
        hashkey:_hashkey
      }
      person {
        name
        full_name
        sports
        date_of_birth
        player {
          account
          sport
          key
          _hashkey
        }
        country {
          account
          key
          _hashkey
        }
        roles
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const MatchNameQuery = gql`
query MatchNameQuery(
  $resource: MatchInput!,
) {
  sports_cricket_match_name(resource: $resource) {
    name
    match {
      name
      internal_name
    }
  }
}
`;

export const MatchLoadWeatherQuery = gql`
query MatchLoadWeatherQuery($resource: MatchInput!) {
  sports_cricket_match_load_weather(resource: $resource) {
    play {
      play_status
      break_type
      break_seconds
      break_note
      internal_notes
      public_msg
      day
      weather {
        temperature
        condition
        icon
      }
      expected_start_time
    }
  }
}
`;

export const MatchFlushCacheMutation = gql`
mutation MatchFlushCacheMutation(
  $resource: MatchInput!,
  $kind: MatchControlKind!,
) {
  sports_cricket_match_controls(resource: $resource, kind: $kind) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const MatchUpdateSessionMutation = gql`
  mutation MatchUpdateSessionMutation(
    $resource: MatchInput!,
    $sessions: MatchSessionsInput!,
    $version: RecordStage
  )
  {
    sports_cricket_match_update_sessions(
      resource: $resource,
      sessions: $sessions,
      version: $version
    ) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;

export const MatchUpdateSessionCreateMutation = gql`
  mutation MatchUpdateSessionCreateMutation(
    $resource: MatchInput!
    $session: MatchSessionItemInput!
    $playStatus: CricketMatchPlayStatus
    $version: RecordStage){
    sports_cricket_match_update_sessions_create(resource: $resource, session: $session,  play_status: $playStatus  version: $version) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      } 
    }
  }
`;
