import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Config } from 'config';
import { Box } from 'core/Box';
import { Label12Regular, Text16Regular } from 'style/typography';
import { ReactComponent as TriangleUpIcon } from 'assets/icons/icon-triangle-up.svg';
import { ReactComponent as EmptyStateIcon } from 'assets/icons/icon-empty-state.svg';
import { asRem } from 'lib/css';

const StatusLoadingWrapper = styled.div`
  color: var(--color-button-primary-bg);
  font-style: italic;
`;

export function StatusLoading({ message }) {
  const msg = message || 'Loading...';
  return (
    <StatusLoadingWrapper>
      <Box center>
        {msg}
      </Box>
    </StatusLoadingWrapper>
  );
}

StatusLoading.propTypes = {
  message: PropTypes.string,
};

const StatusErrorWrapper = styled.div`
  color: var(--color-error);
  .error-state-block {
    text-align: center;

    .text-16-regular {
      margin-top: ${asRem(20)};
      color: var(--color-text-1)
    }
  }
`;

export function StatusError({ error, log = true, className = null }) {
  if (!Config.IsProd && log) {
    console.log('Error');
    console.error(error);
  }
  const errors = error
    && error.networkError
    && error.networkError.result
    && error.networkError.result.errors;

  return (
    <StatusErrorWrapper className={className ?? ''}>
      {(error.message === 'Not found' || error.msg === 'Not found') ? (
        <div className="error-state-block">
          <EmptyStateIcon />
          <Text16Regular>This resource has been either deleted or does not exist.</Text16Regular>
        </div>
      ) : (
        <ErrorLine msg={error ? (error.message || error.msg) : 'Oops something is wrong!'} />
      )}

      {errors && errors.map((err, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          {err.message !== 'Not found'
            ? <ErrorLine msg={err.message} />
            : (
              <div className="error-state-block">
                <EmptyStateIcon />
                <Text16Regular>
                  This resource
                  has been either deleted or does not exist.
                </Text16Regular>
              </div>
            )}
        </div>
      ))}
    </StatusErrorWrapper>
  );
}

StatusError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    msg: PropTypes.string,
    networkError: PropTypes.object,
  }),
  log: PropTypes.bool,
  className: PropTypes.string,
};

const StatusEmptyWrapper = styled.div`
  color: var(--color-button-primary-bg);
  font-style: italic;
  text-align: center;
`;

export function StatusEmpty({ message }) {
  const msg = message || 'You got nothing here!';
  return (
    <StatusEmptyWrapper>
      <Box center>
        {msg}
      </Box>
    </StatusEmptyWrapper>
  );
}

StatusEmpty.propTypes = {
  message: PropTypes.string,
};

const ResponseStatusWrapper = styled.div`
  color: var(--color-button-primary-bg);
  margin-bottom: var(--layout-padding-contained);
`;

export function ResponseStatus({ status }) {
  return (
    <ResponseStatusWrapper>
      {status && status.okay
        ? (
          <div>✅ All done!</div>
        )
        : (
          <div>
            {!status && (
              <StatusError
                error={{ message: 'Status missing' }}
                log={false}
              />
            )}
          </div>
        )}
      {status && status.errors && status.errors.map((item, i) => (
        <StatusError
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          error={{ message: item.msg }}
          log={false}
        />
      )) }
    </ResponseStatusWrapper>
  );
}

ResponseStatus.propTypes = {
  status: PropTypes.shape({
    okay: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.shape({
      msg: PropTypes.string.isRequired,
      code: PropTypes.string,
    })),
  }),
};

const ErrorLineWrapper = styled.div`
  color: var(--color-error);
  display: flex;
  align-items: center;
  padding: 0px;
  gap: ${asRem(4)};
  .label-12-regular {
    max-width: ${asRem(700)};
    word-break: break-word;
  }
  svg {
    min-width: ${asRem(12)};
    max-height: ${asRem(12)};
  }
`;

export function ErrorLine({ msg, className = '', onClick = null }) {
  if (!msg) {
    return null;
  }

  return (
    <ErrorLineWrapper className={`error-line ${className}`} onClick={onClick}>
      <TriangleUpIcon />
      <Label12Regular>{msg}</Label12Regular>
    </ErrorLineWrapper>
  );
}

ErrorLine.propTypes = {
  msg: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
