import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import {
  TournamentCreateMutation,
  TournamentReadQuery,
  TournamentSummaryQuery,
  TournamentSearchQuery,
  TournamentTopCountsQuery,
  TournamentUpdateMutation,
  TournamentBasicInfoUpdateMutation,
  TournamentTeamUpdateMutation,
  TournamentAllTeamSearch,
  TournamentUpdateStatusMutation,
  TournamentTeamNameQuery,
  TournamentNameQuery,
  TournamentImageUploadMutation,
  TournamentResultMutation,
  TournamentRulesMutation,
  TournamentTeamListQuery,
} from './query';
import { TournamentImageView } from './ResourceImage';

function ValidateTeam() {
  const ValidateTeamItem = (value) => {
    const squad = objectAsArray(value);
    return squad.filter((x) => !x).length === 0;
  };

  return Yup.mixed()
    .test(
      'error',
      ValidateTeamItem,
    );
}

export const TournamentResource = Resource({
  resourceId: 'tournament',
  name: 'Tournament',
  storeId: 'Tournament',
  pageKey: 'sports.cricket.tournament',
  // subPagekey: 'teams',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Tournament',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        internal_name: StringFieldMinMax(3, 120),
        short_name: StringFieldMinMax(2, 24),
        // start_date: Yup.string().required(),
        // gender: Yup.string().required(),
      }),
    }),
    ColumnSet({
      name: 'bio',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        internal_name: StringFieldMinMax(3, 120),
        short_name: StringFieldMinMax(2, 24),
        // start_date: Yup.string().required(),
        gender: Yup.string().required(),
        tournament_type: Yup.string().nullable().default(''),
        pointing_system: Yup.string().nullable().default(''),
        administrators: Yup.array().nullable().default([]),
        hosts: Yup.array().nullable().default([]),
      }),
      historyKey: 'TournamentBio',
    }),
    ColumnSet({
      name: 'Cricket',
      shape: Yup.object().shape({
        format: Yup.string().required(),
        mg: Yup.string().required(),
      }),
      historyKey: 'CricketTournamentCricket',
    }),
    ColumnSet({
      name: 'Rules',
      shape: Yup.object().shape({
        overs_per_innings: Yup.number().nullable(),
        drs_per_innings: Yup.number().nullable(),
        balls_per_over: Yup.number().nullable(),
        player_per_team: Yup.number().nullable(),
        bench_per_team: Yup.number().nullable(),
        bowler_per_innings: Yup.number().nullable(),
        batsman_per_innings: Yup.number().nullable(),
        runs_per_no_ball: Yup.number().nullable(),
        runs_per_wide: Yup.number().nullable(),
      }),
      viewKey: 'rules',
      historyKey: 'CricketTournamentRules',
    }),
    ColumnSet({
      name: 'Result',
      shape: Yup.object().shape({
        winner: Yup.mixed(),
        runner_up: Yup.mixed(),
        players_of_the_series: Yup.mixed(),
      }),
    }),
    ColumnSet({
      name: 'CricketTournamentTeam',
      shape: Yup.object().shape({
        teams: ValidateTeam(),
      }),
      viewKey: 'cricket_tournament_team',
      historyKey: 'CricketTournamentCricketTournamentTeam',
    }),
    ColumnSet({
      name: 'Image',
      shape: Yup.object().shape({
        image_url: Yup.string().nullable().default(''),
      }),
    }),
    ColumnSet({
      name: 'Status',
      shape: Yup.object().shape({
        published: Yup.boolean().default(false),
        notes: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
    }),
    ColumnSet({
      name: 'Computed',
      shape: Yup.object().shape({
        start_date: Yup.string().nullable(),
        end_date: Yup.string().nullable(),
        first_match: Yup.string().nullable(),
        start_date_updated_round: Yup.string().nullable(),
        end_date_updated_round: Yup.string().nullable(),
      }),
      viewKey: 'computed',
      historyKey: 'TournamentComputed',
    }),
    ColumnSet({
      name: 'TotalMatchesCoount',
      shape: Yup.object().shape({
        total_matches: Yup.number().nullable(),
      }),
      viewKey: 'total_matches_count',
      historyKey: 'TournamentTotalMatchesCount',
    }),
  ],
  listPrimaryActions: [
    { action: 'update', page: 'update.basic' },
    // { action: 'updateTeam' },
    // { action: 'updateBasicInfo' },
    { action: 'updateImage', page: 'update.image' },
    // { action: 'updateStatus' },
  ],
  imageRender: (item) => (<TournamentImageView item={item} size="24" />),
  queries: {
    read: QueryAction({
      query: TournamentReadQuery,
      resourceNamePath: 'item.tournament.internal_name',
      resourcePath: 'item.resource.hashkey',
      responsePath: 'sports_tournament_read',
    }),
    summary: QueryAction({
      query: TournamentSummaryQuery,
      resourcePath: 'tournament.resource.hashkey',
      resourceNamePath: 'tournament.tournament.internal_name',
      responsePath: 'sports_tournament_summary',
    }),
    list: QueryAction({ query: TournamentSearchQuery, responsePath: 'sports_tournament_search' }),
    search: QueryAction({
      query: TournamentSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'tournament.internal_name',
      responsePath: 'sports_tournament_search',
    }),
    counts: QueryAction({
      query: TournamentTopCountsQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'tournament.internal_name',
      responsePath: 'sports_tournament_search.top_counts',
    }),
    allTeamSearch: QueryAction({
      query: TournamentAllTeamSearch,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'team.name',
      responsePath: 'sports_cricket_tournament_team_all_teams_search',
    }),
    name: QueryAction({
      query: TournamentTeamNameQuery,
      responsePath: 'sports_cricket_tournament_team_name',
      resourceNamePath: 'sports_cricket_tournament_team_name.name',
    }),
    nameQuery: QueryAction({
      query: TournamentNameQuery,
      responsePath: 'sports_tournament_name',
      resourceNamePath: 'sports_tournament_name.name',
    }),
    tournamentTeamList: QueryAction({
      query: TournamentTeamListQuery,
      resourceNamePath: 'item.tournament.internal_name',
      resourcePath: 'item.resource.hashkey',
      responsePath: 'sports_tournament_read',
    }),
  },
  mutations: {
    update: MutationAction({ mutation: TournamentUpdateMutation, cs: ['tournament', 'bio', 'result', 'cricket_tournament_team', 'rules', 'cricket', 'computed', 'total_matches_count'] }),
    updateTeam: MutationAction({
      mutation: TournamentTeamUpdateMutation,
      cs: ['cricket_tournament_team'],
      responsePath: 'sports_tournament_update_team',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.cricket_tournament_team.teams = arrayAsObject(
          resp.cricket_tournament_team.teams,
        ) ?? {};
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.cricket_tournament_team.teams = objectAsArray(resp.cricket_tournament_team.teams);
        const postVariables = {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: data._hashkey,
          },
          cricketTournamentTeam: {
            teams: resp.cricket_tournament_team.teams,
          },
        };
        return postVariables;
      },
    }),
    updateBasicInfo: MutationAction({
      mutation: TournamentBasicInfoUpdateMutation,
      cs: ['tournament'],
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        const postVariables = {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: data._hashkey,
          },
          tournament: resp.tournament,
        };
        return postVariables;
      },
      responsePath: 'sports_tournament_basic_update',
    }),
    updateResult: MutationAction({
      mutation: TournamentResultMutation,
      cs: ['result'],
      responsePath: 'sports_tournament_update_result',
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.result.winner = arrayAsObject(resp?.result?.winner) || {};
        resp.result.runner_up = arrayAsObject(resp?.result?.runner_up) || {};
        resp.result.players_of_the_series = arrayAsObject(resp?.result?.players_of_the_series)
         || {};
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.result.winner = objectAsArray(resp?.result?.winner) || [];
        resp.result.runner_up = objectAsArray(resp?.result?.runner_up) || [];
        resp.result.players_of_the_series = objectAsArray(resp?.result?.players_of_the_series)
        || [];
        return resp;
      },
    }),
    updateRules: MutationAction({
      mutation: TournamentRulesMutation,
      cs: ['rules'],
      responsePath: 'sports_tournament_rules_udpate',
    }),
    updateImage: MutationAction({ mutation: TournamentImageUploadMutation, cs: ['image'] }),
    updateStatus: MutationAction({ mutation: TournamentUpdateStatusMutation, cs: ['status'] }),
    create: MutationAction({ mutation: TournamentCreateMutation, responsePath: 'sports_tournament_create' }),
  },
});
