import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { asRem } from 'lib/css';
import {
  SlideUpAndFadeKF,
  SlideDownAndFadeKF,
  SlideRightAndFadeKF,
  SlideLeftAndFadeKF,
} from 'style/keyframes';
import { MutationButton } from './Button';

export const Popover = styled(PopoverPrimitive.Root)`
`;
Popover.Trigger = styled(PopoverPrimitive.Trigger)`
  min-width: ${asRem(24)};
`;

Popover.ContentWrapper = styled(PopoverPrimitive.Content)`
  min-width: ${asRem(220)};
  background-color: var(--color-border-1);
  color: var(--color-text-1);
  border-radius: ${asRem(6)};
  border: 1px solid var(--color-border-2);
  box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);
  padding: ${asRem(10)} ${asRem(10)};
  z-index: 10000;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: 'forwards';


  .options-wrapper {
    padding: 0;

    button, a {
      display: block;
      width: 100%;
      padding: 0;
      padding-bottom: ${asRem(10)};
      div {
        display: flex;
        gap: ${asRem(14)};
        align-items: center;
        color: var(--color-label);

        &:hover {
          color: var(--color-button-primary-bg);
        }
      }
      &.active {
        color: var(--color-button-primary-bg);
        >div {
          color: var(--color-button-primary-bg);
        }
      }
      svg {
        margin-left: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &[data-state="open"] {
    animation-name: ${SlideUpAndFadeKF};
    &[data-side="top"] {
      animation-name: ${SlideDownAndFadeKF};
    }
    &[data-side="right"] {
      animation-name: ${SlideLeftAndFadeKF};
    }
    &[data-side="bottom"] {
      animation-name: ${SlideUpAndFadeKF};
    }
    &[data-side="left"] {
      animation-name: ${SlideRightAndFadeKF};
    }
  }

  &[data-align="end"] {
    /* border-top-right-radius: 0; */
    svg {
      margin-left: ${asRem(8)};
    }
  }

  &[data-align="start"] {
    /* border-top-left-radius: 0; */
    svg {
      margin-right: ${asRem(8)};
    }
  }
`;

Popover.Item = styled.div`
  all: unset;
  line-height: ${asRem(24)};
  font-size: ${asRem(12)};
  padding: 0 ${asRem(6)};
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    span {
      color: var(--color-button-primary-bg);
    }
  }

  svg, span {
    display: block;
  }

  svg {
    fill: var(--color-secondary-bg);
    margin-right: ${asRem(12)};
  }

  &:focus {
    background-color: var(--color-button-primary-bg);
    border-radius: ${asRem(5)};
  }
`;
Popover.Arrow = styled(PopoverPrimitive.Arrow)`
  fill: var(--color-border-1);
`;
Popover.Separator = styled.div`
  height: 1px;
  background-color: var(--color-disabled-text);
  margin: ${asRem(5)};
`;

// Popover.Content = React.forwardRef(
//   // eslint-disable-next-line react/prop-types
//   ({ children, ...props }, forwardedRef) => {
//     return (
//       <Popover.ContentWrapper {...props} ref={forwardedRef}>
//         {children}
//         <Popover.Arrow />
//       </Popover.ContentWrapper>
//     );
//   },
// );

Popover.Content = Popover.ContentWrapper;
Popover.Portal = PopoverPrimitive.Portal;

export function PopoverButtons({
  name,
  iconView,
  buttonClassName,
  mutations,
  renderButtonOptions,
  contentAlign = 'center',
  onSuccess = null,
}) {
  const [keepOpen, setKeepOpen] = useState(false);
  const onButtonHandled = (variables) => {
    setKeepOpen(false);
    onSuccess({ variables });
  };

  const close = () => {
    setKeepOpen(false);
  };

  return (
    <Popover
      open={keepOpen}
      onOpenChange={(openChange) => setKeepOpen(openChange)}
      setKeepOpen
    >
      <Popover.Trigger className={buttonClassName}>
        {iconView}
        {name || ''}
      </Popover.Trigger>
      <PopoverPrimitive.Portal>
        <Popover.Content align={contentAlign}>
          {mutations && (
            <div>
              {mutations.map((mutation, index) => (
                <React.Fragment key={mutation.name}>
                  <Popover.Item>
                    <MutationButton
                      {...mutation}
                      onSuccess={() => onButtonHandled(mutation.variables)}
                    />
                  </Popover.Item>
                  {index < (mutations.length - 1) && (
                  <Popover.Separator />
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          {renderButtonOptions && renderButtonOptions({ close })}
        </Popover.Content>
      </PopoverPrimitive.Portal>
    </Popover>
  );
}

PopoverButtons.propTypes = {
  mutations: PropTypes.arrayOf(PropTypes.shape({
    ...MutationButton.propTypes,
  })),
  name: PropTypes.string,
  contentAlign: PropTypes.string,
  renderButtonOptions: PropTypes.func,
  iconView: PropTypes.node,
  buttonClassName: PropTypes.string,
  onSuccess: PropTypes.func,
};
