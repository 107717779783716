import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  SportsPinResourceCreateMutation,
  SportsPinResourceDeleteMutation,
  SportsPinResourceReadQuery,
} from 'sports/query';
// import { resourceHashkey } from 'lib/utils';
import { asRem } from 'lib/css';
import { StatusError, StatusLoading } from 'core/Status';
import { MutationButton } from 'core/Button';

const PinActionsWrapper = styled.div`
button {
  display: block;
  width: 100%;
  margin-bottom: ${asRem(12)};
}
`;

export function PinButton({
  create,
  noBorder,
  resourceType,
  resourceKey,
  resourceName,
  imageUrl,
  userLevel,
}) {
  const params = {
    resourceType,
    resourceKey,
    resourceImageUrl: imageUrl,
  };

  const [isCreate, setIsCreate] = useState(create);

  if (isCreate) {
    params.resourceName = resourceName;
  }

  const responseKey = isCreate ? 'sports_pin_resource_create.status' : 'sports_pin_resource_delete.status';
  let name = isCreate ? 'Pin' : 'Unpin';
  if (!userLevel) {
    name = isCreate ? 'Pin for everyone' : 'Unpin for everyone';
  }

  if (isCreate) {
    return (
      <MutationButton
        className={`plain ${noBorder ? '' : 'bordered'}`}
        mutation={SportsPinResourceCreateMutation}
        responseKey={responseKey}
        onSuccess={() => setIsCreate(false)}
        name={name}
        variables={
          {
            ...params,
            accountLevel: !userLevel,
            userLevel,
          }
        }
      />
    );
  }

  return (
    <MutationButton
      className={`plain ${noBorder ? '' : 'bordered'}`}
      mutation={SportsPinResourceDeleteMutation}
      responseKey={responseKey}
      onSuccess={() => setIsCreate(true)}
      name={name}
      variables={
        {
          ...params,
          accountLevel: !userLevel,
          userLevel,
        }
      }
    />
  );
}

PinButton.propTypes = {
  create: PropTypes.bool.isRequired,
  noBorder: PropTypes.bool,
  userLevel: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceKey: PropTypes.any,
  imageUrl: PropTypes.string,
  resourceName: PropTypes.string,
};

export function PinActions({
  resourceType, resourceKey, resourceName, imageUrl,
}) {
  // const hashedKey = resourceHashkey({ key: resourceKey });
  const hashedKey = resourceKey;
  const {
    loading,
    error,
    data,
  } = useQuery(SportsPinResourceReadQuery, {
    variables: {
      resourceType,
      resourceKey: hashedKey,
    },
  });

  const resp = data && data.sports_pin_resource_read;
  const userLevel = resp && resp.user_level;
  const accountLevel = resp && resp.account_level;
  const params = {
    resourceType,
    resourceKey: hashedKey,
    resourceName,
    imageUrl,
  };

  return (
    <PinActionsWrapper className="pin-actions">
      {loading && (<StatusLoading />)}
      {error && (<StatusError error={error} />)}
      {resp && (
        <>
          {userLevel
            ? (<PinButton create={false} userLevel {...params} />)
            : (<PinButton create userLevel {...params} />)}
          {accountLevel
            ? (<PinButton create={false} userLevel={false} {...params} />)
            : (<PinButton create userLevel={false} {...params} />)}
        </>
      )}
    </PinActionsWrapper>
  );
}

PinActions.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceKey: PropTypes.any,
  resourceName: PropTypes.string,
  icon: PropTypes.bool,
  imageUrl: PropTypes.string,
};
