import { FantasyEditorResource } from 'Fantasy/fantasyCreditEditor/resource';
import { CuratedMatchesResource } from 'Fantasy/CuratedMatches/resource';
import { AssociationResource } from './association/resource';
import { RegionResource } from './region/resource';
import { StadiumResource } from './stadium/resource';
import { ClubResource } from './club/resource';
import { TeamResource } from './team/resource';
import { TournamentRoundResource } from './cricket/tournament/rounds/resource';
import { TournamentTeamResource } from './cricket/seasonTeam/resource';
import { TournamentResource } from './cricket/tournament/resource';
import { CompetitionResource } from './cricket/competition/resource';
import { PlayerResource } from './cricket/players/resource';
import { MatchResource } from './cricket/match/resource';
import { PersonResource } from './cricket/Person/resource';
import { TourResource } from './cricket/tour/resource';
import { TeamRankingResource } from './cricket/Ranking/Team/resource';
import { PlayerRankingResource } from './cricket/Ranking/Player/resource';
import { InningsResource } from './cricket/match/update/scoreEditor/inningsResource';

export const Resources = {
  Association: AssociationResource,
  Region: RegionResource,
  FantasyMatch: FantasyEditorResource,
  Team: TeamResource,
  Stadium: StadiumResource,
  Club: ClubResource,
  Tour: TourResource,
  Tournament: TournamentResource,
  Competition: CompetitionResource,
  Player: PlayerResource,
  Person: PersonResource,
  Match: MatchResource,
  TournamentTeam: TournamentTeamResource,
  TournamentRound: TournamentRoundResource,
  Curation: CuratedMatchesResource,
  Ranking: TeamRankingResource,
  CricketTeamRanking: TeamRankingResource,
  CricketPlayerRanking: PlayerRankingResource,
  MatchInnings: InningsResource,
};

export function getResourceDef(id) {
  return Resources[id] ?? null;
}
