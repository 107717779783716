import { gql } from '@apollo/client';

export const SportsPinResourceCreateMutation = gql`
mutation SportsPinResourceCreateMutation(
  $resourceKey: String!,
  $resourceType: ManageableResource!,
  $resourceName: String!,
  $accountLevel: Boolean,
  $resourceImageUrl: String,
  $userLevel: Boolean) {
    sports_pin_resource_create (
     resource_key: $resourceKey,
     resource_type: $resourceType,
     resource_name: $resourceName,
     account_level: $accountLevel,
     resource_image_url: $resourceImageUrl,
     user_level: $userLevel
      ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const SportsPinResourceDeleteMutation = gql`
mutation SportsPinResourceDeleteMutation(
  $resourceKey: String!,
  $resourceType: ManageableResource!,
  $accountLevel: Boolean,
  $userLevel: Boolean) {
    sports_pin_resource_delete (
     resource_key: $resourceKey,
     resource_type: $resourceType,
     account_level: $accountLevel,
      user_level: $userLevel
      ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const SportsPinResourceReadQuery = gql`
query SportsPinResourceReadQuery (
  $resourceKey: String!,
  $resourceType: ManageableResource!,
) {
  sports_pin_resource_read(
    resource_key: $resourceKey,
    resource_type: $resourceType,
  ) {
    account_level {
      resource {
        account
        prefix
        user_key
        resource_type
        resource_key
        hashkey: _hashkey
      }
      pinned_resource {
        resource_key
        resource_type
        resource_name
        user
        account_level
        created
      }
    }
    user_level {
      resource {
        account
        prefix
        user_key
        resource_type
        resource_key
        hashkey: _hashkey
      }
      pinned_resource {
        resource_key
        resource_type
        resource_name
        user
        account_level
        created
      }
    }
  }
}
`;

export const SportsPinResourceList = gql`
query SportsPinResourceList (
  $resourceType: ManageableResource,
  $includeAccountLevel: Boolean,
  $includeUserLevel: Boolean,) {
  sports_pin_resource_list(resource_type: $resourceType,
   include_account_level: $includeAccountLevel,
   include_user_level: $includeUserLevel) {
    items {
      resource {
        account
        prefix
        user_key
        resource_type
        resource_key
        hashkey: _hashkey
      }
      pinned_resource {
        resource_key
        resource_type
        resource_name
        user
        account_level
        created
        resource_image_url
      }
    }
  }
}
`;

export const SportsActivityList = gql`
query SportsActivityList (
  $account: String, $topic: [String!]) {
  ants_sports_activity_list(account: $account, topic: $topic) {
      items {
        hashkey, topic
        item {
          msg
          ts
          payload
          tags
          resource_name
          resource_type
          resource_key
          resource_image_url
          author {
            key
            name
            ts
            email
            picture_key
            ip
          }
          user_key
        }
      }
      page {
        next_page_key
        count
      }
    }
}
`;

export const SportsResourcePropsReadQuery = gql`
query SportsResourcePropsReadQuery (
  $resource: ResourcePropsInput!) {
  sports_cricket_resource_props_read(resource: $resource) {
    resource_props {
      resource {
        account
        resource_id
        _hashkey
      }
      tags {
        tagnames
        last_updated
      }
      properties {
        properties {
          name
          store_type
          value_type
        }
        last_updated
      }
    }
  }
}
`;

export const SportsResourceTagsCreateMutation = gql`
mutation SportsResourceTagsCreateMutation (
  $resource: ResourcePropsInput!,
  $tags: ResourcePropsTagsInput) {
    sports_cricket_resource_props_tag_create(resource: $resource,
     tags: $tags) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;

export const SportsResourcePropsCreateMutation = gql`
mutation SportsResourcePropsCreateMutation (
  $resource: ResourcePropsInput!,
  $properties: ResourcePropsPropertiesInput) {
    sports_cricket_resource_props_property_create(resource: $resource,
     properties: $properties) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
    }
  }
`;
